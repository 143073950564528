import styled, { css } from "styled-components"

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  transition: opacity  0.25ms ease;
  flex-basis: 50%;
  h2 {
    margin-bottom: 1.5rem;
  }
  
  .header-right {
    span {
      font-weight: 500;
      font-size: 1.3rem;
      display: inline-block;
      transform: translateY(3px);
      margin: 0 .5rem;
    }
    a {
      font-weight: 500;
      font-size: 1.5rem;
      letter-spacing: 0;
      color: black;
      text-transform: lowercase;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    flex-basis: 100%;
   
  }
`
export const SiteTitle = styled.h1`
  font-size: 1.5rem;
  letter-spacing: 0;
  margin-bottom: .25rem;
  z-index: 99999;
  position: relative;
  color: black;
  &:hover {
    opacity: 0.3;
  }
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  letter-spacing: 0.0065em;
  font-weight: 500;
  color: black;
  @media screen and (max-width: 768px) {
    font-size: 1.35rem;
    letter-spacing: 0em;
    margin-top: 0rem;
  }
`