import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { HeaderWrapper, SiteTitle, SubTitle } from "./styles"
import SplitText from 'react-pose-text';
import posed from 'react-pose';

const Sidebar = posed.div({
  exit: {
    x: '0%'
  },
  enter: {
    x: '0%',
    beforeChildren: true,
    staggerChildren: 1
  }
});

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 2,
    transition: ({ charInWordIndex }) => ({
      type: 'spring',
      delay: charInWordIndex * 10,
    })
  }
};


const Header = ({ siteTitle, description, page }) => {
  return (
    <HeaderWrapper>
      <div className="header-left">
        <Link to="/" className="homepage-link">
          <SiteTitle page={page}>
            <Sidebar className="sidebar" initialPose="exit" pose="enter">
              <SplitText charPoses={charPoses}>{siteTitle}</SplitText>
            </Sidebar>
          </SiteTitle>
          <SubTitle>
            <Sidebar className="sidebar" initialPose="exit" pose="enter">
              <SplitText charPoses={charPoses}>{description}</SplitText>
            </Sidebar>
          </SubTitle>
        </Link>
      </div>
      <div className="header-right">
        <Sidebar className="sidebar" initialPose="exit" pose="enter">
          <Link
            to="/all">
            <SplitText charPoses={charPoses}>View All</SplitText>
          </Link>
          <span> &bull; </span>
          <a target="_blank" href="https://www.instagram.com/nomoore/"><SplitText charPoses={charPoses}>Instagram</SplitText></a>
          <span> &bull; </span>
          <Link
            to="/about">
            <SplitText charPoses={charPoses}>About</SplitText>
          </Link>
        </Sidebar>
      </div>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  description: PropTypes.string,
  page: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  description: "",
  page: "",
}

export default Header
