import styled, { css } from "styled-components"

interface DefaultLink {
  fontSize: number,
  textTransform: string,
  letterSpacing: number,
  marginBottom: number,
  marginRight: number,
  fontWeight: string
}

export const SiteNavLink = styled.div<DefaultLink>`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  margin-bottom: 0;
  font-weight: 500;
  h3 {
    margin-top: 1rem;
    text-transform: lowercase;
  }
  &.about {
    margin-left: auto;
    margin-right: 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    h3{
      margin-bottom: 0;
      font-size: 1.5rem !important;
    }
    a, a h3 {
      font-size: 1.5rem !important;
      margin-bottom: 1rem;
      display: block;
      line-height: initial !important;
    }
  }
`

export const SiteNavLinkHover = styled.div`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.025rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
  font-weight: 500;
`


export const Nav = styled.nav`
  width: 100%;
  .about-wrapper {
    margin: 2rem 0;
  }
      @media screen and (min-width: 768px) {
        text-align: right;
      }
      @media screen and (max-width: 768px) {
        ${props => props.open === true  && css`
            display: block !important;
            position: fixed;
            top: 0px;
            width: 100%;
            left: 0;
            height: calc(100vh );
            z-index: 999;
            background-color: white;
            padding: 8rem 1.4rem;
            a {
              font-size: 2rem;
              color: #9a64c0;
              color: black;
              h3 {
                text-transform: capitalize;
              }
            }
      
      }
  `}
  
  div {
    display: block;
    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;
    }
    a {
      text-transform: capitalize;
      letter-spacing: 0;
      font-size: 1.5rem;
      color: black;
      ${props => props.page !== "/" && css`
        font-size: 1.5rem;
        line-height: 1.8em;
      `}
    }
    span {
      display: none;
      margin: 0 0.5rem;
      @media screen and (min-width: 768px) {
        display: inline-block;
      }
    }
    &:last-child {
      span {
        display: none;
      }
    }
  }
`