import React from "react"
import styled from "styled-components"
import PostTitle from "./PostTitle"
import { SiteNavLink, Nav } from "./styles"
import { GridWrapper } from "../../styles.js"
import { Link } from "gatsby"

interface Category {
  categoryThumbnail: Object,
  title: String
}

type NavLinks = {
  deDup: Array<Category>,
  page: String,
  open: Boolean,
  toggleNav: Function
} 

export default ({ deDup, page, open, toggleNav }: NavLinks) => {
    return (
      <Nav page={page} open={open}>
        <GridWrapper>
          {deDup.map((el: Category, i: Number) => (
            <PostTitle 
              toggleNav={toggleNav} 
              img={el.categoryThumbnail} 
              page={page}
              key={i} 
              title={el.title.toLowerCase()} />
          ))}
          <SiteNavLink><Link onClick={e => toggleNav(e)} to="/contact"><h3>Contact</h3></Link></SiteNavLink>
          <SiteNavLink><Link onClick={e => toggleNav(e)} to="/about"><h3>About</h3></Link></SiteNavLink>
          <SiteNavLink><a target="_blank" href="https://www.instagram.com/nomoore/">Instagram</a></SiteNavLink>
        </GridWrapper>
      </Nav>
    )
}