import styled, { css } from "styled-components"

export const HeaderColors = styled.div`
    position: sticky;
    top: 0;
    z-index: 10000;
    color: black;
    display: inline-block;

  h1,
  a {
    font-family: "Almarai", sans-serif;
    font-weight: 500;
   
  }
  h3,
  h2,
  small,
  p,
  div {
    font-family: "Almarai", sans-serif;
  }
`

// General Styles
export const Center = styled.div`
  text-align: left;
  @media screen and (min-width: 768px) {
    text-align: center;
    margin: 0 auto;
  }
`

export const VerticalCenter = styled(Center)`
  display: flex;
  align-items: center;
`

export const MainWrapper = styled.div`
  position: absolute;
  width: calc(100% - 4rem);
 
  @media screen and (max-width: 768px) {
    width: calc(100% - 2.5rem);
    padding-bottom: 2rem;
  }
`;


export const GridWrapper = styled.div`
  display: flex !important;
  justify-content: ${props => props.justify};
  > div {
    width: calc(25% - 1.5rem);
    @media screen and (max-width: 1024px) {
      width: calc(50% - 1.5rem);
    }
    a {
      text-decoration: none;
      @media screen and (max-width: 768px) {
        font-size: 2rem;
      }
    }
    h3 {
      color: black;
      margin-top: 1rem;
      &.text-center {
          text-align: center;
        }
      @media screen and (max-width: 768px) {
        &.homepage-categories {
          font-size: 1.3rem;
        } 
       
        font-size: 2rem;
      }
    }
  }
  @media screen and (max-width: 850px){
    display: block !important;
    > div {
      width: 100% !important;
    }
  }
`;

export const BodyContainer = styled.div`
  max-width: 650px;
  margin: 0rem 0 2.5rem;
  line-height: 1.35em;
  letter-spacing: 0;
  p {
    margin-bottom: 1rem;
    a {
      margin-left: .5rem;
      /* text-decoration: underline; */
    }
  }
  b {
    /* font-size: 1.15rem; */
    font-weight: 500;
    text-decoration: underline;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0rem;
    font-size: 1rem;
    input,textarea {
      width: 100%;
    }
  }
  a {
    color: black;
    &:visited {
      color: black;
    }
  }

  input,textarea {
    margin: .5rem 0;
    border-radius: 2px;
    border: 1px solid lightgray;
    padding: .5rem 1rem;
  }

  input[type="submit"] {
    background-color: lightgray;
    text-transform: uppercase;
    padding: 6px 15px;
    font-size: 1rem;
    letter-spacing: 1px;
    min-width: 200px;
  }

`

export const MobileStacking = styled.div`
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 100%;
  }
`

export const NavWrapper = styled.div`
  display: none;  
  @media screen and (max-width: 768px) {
    display: ${props => props.open ? 'block' : 'none'};
    a {
      text-decoration: none;
    }
  }

`;

export const MobileNavButton = styled.div`
  display: none;
  margin-top: 0.8rem;
  position: relative;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    display: block;
    position: fixed;
    top: 1.25rem;
    right: 1.5rem;
  }
  span {
    display: block;
    height: 1px;
    width: 25px;
    margin: 6px 0;
    background-color: black;
    transition: 0.25s ease;
    ${props =>
    props.open &&
    css`
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(1) {
          transform: translateY(5px) rotate(-45deg);
        }
        &:nth-child(3) {
          transform: translateY(-1px) rotate(45deg);
        }
      `}
  }

  ${props =>
    props.page === "/" &&
    css`
      display: none;
    `}
`
export const LayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 1.25rem;
  top: 0;
  transition: 0.25s linear;
  position: relative;
  font-size: 1.5rem;
  max-width: 1600px;
  margin: 0 auto;
  
  h3,
  h2,
  small,
  p,
  div {
    font-family: "Almarai", sans-serif;
  }
  a {
    text-decoration: none;
  }
  .box {
    border: 1px solid #bfcebf;
    border-radius: 12px;
    padding: 1.5rem;
  }

  .mobile-only {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .desktop-only {
    display: block;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  ${props =>
    props.page !== "/" &&
    css`
          nav img, nav .gatsby-image-wrapper{
              display: none !important;
          }
        
      `}
  
  ${props =>
    props.page === "/" &&
    css`
      nav > div > div{
        width: calc(25% - 1.5rem);
      }
          
  `}
  

  @media screen and (min-width: 768px) {
    padding: 2rem 2rem;
  a {
    text-decoration: none;
    transition: 0.25ms ease;
    &:hover {
      opacity: 0.3;
    }
  }

  ${props =>
    props.page !== "/" &&
    css`
      .about-wrapper, nav {
        display:none;
      }
    `}
  }
`

