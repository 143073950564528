import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { SiteNavLink } from './styles'

interface Title  {
    title: String,
    toggleNav: Function
};

const PostTitle = ({ title, toggleNav }: Title) => {
    return (
    <SiteNavLink>
        <Link onClick={e => toggleNav(e)} to={`/${title}`}>
            <h3>{title && title} </h3>
        </Link> 
    </SiteNavLink>
    )
}

PostTitle.defaultProps = {
    title: ''
}

export default PostTitle
