import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { uuid as key } from "uuidv4"
import Img from "gatsby-image"
import Nav from "./components/nav/index.tsx"
import Header from "./components/header/header"
import "./layout.css"
import {
  HeaderColors,
  LayoutWrapper,
  MobileNavButton,
  NavWrapper,
  MainWrapper,
  GridWrapper,
} from "./styles.js"
import SEO from "./seo"
import Transition from "./components/transitions"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query siteTitleQueryAndSiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      allContentfulPost {
        nodes {
          tags
        }
      }
      contentfulNavigation {
        navElements {
          title
          categoryThumbnail {
            sizes {
              src
            }
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  `)
  const [open, setOpen] = useState(false)
  let currentTop = 0;
  const toggleNav = (e) => {

    if (open) {
      document.querySelector('html').style.overflow = "initial"
      // window.scrollTo({ top: currentTop });
    }
    else {
      // currentTop = window.scrollY;
      // window.scrollTo({ top: currentTop });
      document.querySelector('html').style.overflow = "hidden"
    }
    setOpen(!open)
  }

  return (
    <LayoutWrapper page={location.pathname}>
      <SEO title="Home" />
      <HeaderColors className="header-colors">
        <Header
          description={data.site.siteMetadata.description}
          siteTitle={data.site.siteMetadata.title}
          page={location.pathname}
        />
        <MobileNavButton
          open={open}
          page={location.pathname}
          onClick={(e) => toggleNav(e)}
          id="hamburger-nav"
        >
          <span />
          <span />
          <span />
        </MobileNavButton>
      </HeaderColors>
      <Transition location={location}>
        <MainWrapper>
          <NavWrapper open={open}>
            <Nav
              deDup={data.contentfulNavigation.navElements}
              toggleNav={toggleNav}
              page={location.pathname}
              open={open}
            />
          </NavWrapper>
          <div>
            <main>
              <GridWrapper
                justify={data.contentfulNavigation.navElements.length < 2 ? "center" : "space-between"}>
                {location.pathname === "/" &&
                  data.contentfulNavigation.navElements.map((el, i) => {
                    const img = el.categoryThumbnail
                    return (
                      <div key={key(i)}>
                        <Link to={`/${el.title.toLowerCase()}`}>
                          {img && img.fluid && (
                            <Img
                              fluid={img.fluid}
                              backgroundColor="true"
                              style={{ maxHeight: "550px", minHeight: "550px" }}
                            />
                          )}
                          <h3 className={`homepage-categories ${el.title === "All" && "text-center"}`}>
                            {el.title === "All" ? "Enter" : el.title}
                          </h3>
                        </Link>
                      </div>
                    )
                  })}
              </GridWrapper>
              {children}
            </main>
          </div>
        </MainWrapper>
      </Transition>
    </LayoutWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

Layout.defaultProps = {
  location: {
    pathName: "",
  },
}

export default Layout
